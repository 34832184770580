<template>
    <div class="p-3">
        <div class="text-center">
            <el-radio-group v-model="status" @change="loadPlantInsurances(true)">
                <el-radio-button :label="null">全部</el-radio-button>
                <el-radio-button :label="1">待生效</el-radio-button>
                <el-radio-button :label="2">已生效</el-radio-button>
                <el-radio-button :label="3">已过期</el-radio-button>
            </el-radio-group>
        </div>

        <el-card shadow="never" class="mt-3">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadPlantInsurances(true)" />
                </div>
            </div>
            <el-table :data="plantInsurances.data" class="mt-2">
                <el-table-column prop="farmerName" label="种植户" sortable />
                <el-table-column prop="insuranceCompany" label="保险公司" sortable />
                <el-table-column prop="insuranceCategory" label="险种" sortable />
                <el-table-column prop="validityBeginDate" label="有效起始日期" sortable :formatter="$moment.sdFormatter"
                    width="150" />
                <el-table-column prop="validityEndDate" label="有效截止日期" sortable :formatter="$moment.sdFormatter"
                    width="150" />
                <el-table-column prop="insuranceAmount" label="保费金额" sortable />
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="plantInsurances.totalRecords"
                :current-page="plantInsurancesPagination.currentPage" :page-size="plantInsurancesPagination.pageSize"
                class="mt-3" @current-change="plantInsurancesPaginationCurrentChange" />
        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                status: 2,
                key: null,
                plantInsurances: {},
                plantInsurancesPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
            };
        },
        methods: {
            async loadPlantInsurances(resetPage = false) {
                if (resetPage) {
                    this.plantInsurancesPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/PlantInsurance/GetPlantInsurances', {
                    params: {
                        companyId: this.$store.state.companyId,
                        status: this.status,
                        key: this.key,
                        pageIndex: this.plantInsurancesPagination.currentPage - 1,
                        pageSize: this.plantInsurancesPagination.pageSize,
                    }
                });
                this.plantInsurances = response.data;
            },
            plantInsurancesPaginationCurrentChange(page) {
                this.plantInsurancesPagination.currentPage = page;
                this.loadPlantInsurances();
            },
        },
        created() {
            this.loadPlantInsurances();
        },
    };
</script>